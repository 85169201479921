.MuiSelect-outlined.MuiSelect-outlined,
.MuiSelect-outlined.MuiSelect-outlined:active,
.MuiSelect-outlined.MuiSelect-outlined:focus {
  background-color: transparent;
  text-align: center;
  font-family: sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  color: var(--text-color) !important;
}

.mdc-text-field-helper-text {
    color: white !important;
} 

.MuiSelect-select {
  color: var(--text-color) !important;
}