body {
  /* margin: 0; */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.pagination-bar {
  width: 100%;
  display: flex;
  justify-content: center;
}

.MuiTableCell-root {
  padding: 0px !important;
  padding-bottom: 5px !important;
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: var(--text-color) !important;
}

/* WebKit (Chrome, Safari) */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* Firefox */
::-moz-scrollbar {
  width: 8px;
}

::-moz-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

::-moz-scrollbar-thumb:hover {
  background-color: #555;
}

.device-filter {
  display: flex;
  flex-direction: row;
  float: right;
}

@media screen and (max-width: 950px) {
  .device-filter {
    display: flex;
    flex-direction: column;
    float: right;
  }
}