.cluster-marker {
  color: #fff;
  background: #1978c8;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.crime-marker {
    background: none;
    border: none;
}

.crime-marker img{
    margin-top: -20px;
    margin-left: -20px;
    width: 25px;
}

.info-window {
  background-color: #2d396f;
  border-radius: 0.5rem;
  border: 2px solid;
  cursor: pointer;
  padding: 0.5rem;
  width: 250px;
  height: 130px;
  color: blue;
  margin-top: -155px;
  margin-bottom: 25px;
  margin-left: -125px;
  position: absolute;
  z-index: 2;
  border-color: red;
  background-color: white;
}
.close:hover {
  background-color: rgb(192, 190, 190);
}
.close {
  padding: 4px;
  border-radius: 4px;
}

.small {
  font-size: 0.8rem;
}
.date {
  font-size: small;
  margin-left: -10px;
}
.info-window-header {
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  -webkit-box-pack: center;
  justify-content: space-between;
  margin-bottom: 10px;
  padding-inline: 5px;
}
.gm-style .info-window-header img {
  max-width: inherit;
}
.info-window-header button {
  visibility: visible;
}

.info-window-header button::after {
  content: none;
}

.info-window-header img {
  margin-right: 1.5rem;
}

.info-window-header a {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  font-family: mukta-regular;
  font-size: 1.6rem;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  font-weight: bold;
}

.info-window-content {
  background-color: white;
  border-radius: 0.6rem;
  border: none;
  color: black;
  font-family: mukta-regular;
  font-size: 1%;
  display: flex;
  max-height: 80px;
  overflow: auto;
  justify-content: flex-start;
  flex-direction: column;
  padding-inline: 1rem;
}
.info-window-dates {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 16px;
  font-weight: bold;
}
.info-window-image{
  color:#fff;
  min-height:8rem;
  width: 100%;
  height: auto;
}
.info-window-image img{
  width: 100%;       
  height: auto;
  margin-top: 0.4rem;
  border-radius: 0.8rem;
}
.info-window-photo{
  width: 100%;
  height: auto;
}
.info-window-photo img {
  width: 100%;
  height: auto;
  margin-top: 0.4rem;
  border-radius: 0.8rem;
}

.info-window .tracker-info-row {
  display: flex;
  width: 100%;
  align-items: center;
}
.info-window .tracker-info-row.spaced {
  justify-content: space-between;
}
.info-window .tracker-info-row.inlined {
  white-space: nowrap;
}
.info-window .tracker-info-row .tracker-info {
  width: 45%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
}
.info-window .tracker-info-row .tracker-info svg{
  margin-right: 0.5rem;
}
.info-window .tracker-info-window-content {
  flex-direction: column;
}
