// .pagination-container {
//   list-style-type: none;
  
//   .pagination-item {
//     padding: 0 12px;
//     height: 32px;
//     text-align: center;
//     margin: auto 4px;
//     color: rgba(255, 255, 255, 0.87);
//     display: flex;
//     box-sizing: border-box;
//     align-items: center;
//     letter-spacing: 0.01071em;
//     border-radius: 16px;
//     line-height: 1.43;
//     font-size: 13px;
//     min-width: 32px;

//     &.dots:hover {
//       background-color: transparent;
//       cursor: default;
//     }
//     &:hover {
//       background-color: rgba(255, 255, 255, 0.04);
//       cursor: pointer;
//     }

//     &.selected {
//       background-color: rgba(255, 255, 255, 0.08);
//     }

//     .arrow {
//       &::before {
//         position: relative;
//         /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
//         content: "";
//         /* By using an em scale, the arrows will size with the font */
//         display: inline-block;
//         width: 0.4em;
//         height: 0.4em;
//         border-right: 0.12em solid #13a89e;
//         border-top: 0.12em solid #13a89e;
//         font-size: 20px;
//       }

//       &.left {
//         transform: rotate(-135deg);
//       }

//       &.right {
//         transform: rotate(45deg);
//       }
//     }

//     &.disabled {
//       pointer-events: none;

//       .arrow::before {
//         border-right: 0.12em solid #13a89e;
//         border-top: 0.12em solid #13a89e;
//         font-size: 20px;
//       }

//       &:hover {
//         background-color: transparent;
//         cursor: default;
//       }
//     }
//   }
//   .dot-item {
//     padding: 0 12px;
//     height: 32px;
//     text-align: center;
//     margin: auto -10px;
//     color: rgba(255, 255, 255, 0.87);
//     box-sizing: border-box;
//     align-items: center;
//     letter-spacing: 0.01071em;
//     border-radius: 16px;
//     line-height: 1.43;
//     font-size: 13px;
//     min-width: 32px;
//   }
// }


#root {
  // Scss variables which we gonna assign using
  // useState and JavaScript in reactJS
  $background-color: #fff;
  $text-color: #262833;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-template-rows: auto;
}
// rgba(255, 255, 255, 0.08)
.Mui-selected {
  background-color: var(--background-color) !important;
}

.MuiPaginationItem-ellipsis {
  color: var(--text-color) !important;
}