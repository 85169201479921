.crime-marker {
  background: none;
  border: none;
  position: absolute;
}

.crime-marker img {
  width: 25px;
}

.map-marker-icon {
  display: block !important;
  margin-top: 10px !important;
}

.leaflet-container {
  width: 100% !important;
  height: 85vh !important;
  z-index: 0 !important;
}

.geofenses-sidebar-toggle{
  z-index:1;
  position: fixed;
  top: 50%;
  right: 0px;
  background-color: white;
  color: black;
  box-shadow: 1px 0.5px 5px 1px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 20px;
  height:100px;
  text-align: center;
  display:flex;
  flex-direction: column;
  align-items:center;
  justify-content: center;
  width:40px;
}
.leaflet-draw-draw-polygon{
  background-image: url('../../assets/images/plus.png') !important;
  background-size:contain !important;
  background-position: center !important;
}
.geofence-item-row{
  border-bottom:1px grey solid;
}
.actions-holder{
  margin:10px 0 ;
}
.actions-holder img{
  cursor:pointer;
  margin:0 5px;
}
.geofence-history-dialog .MuiDialog-paper	{
  width:500px;
  padding:10px 30px;
}
.geofence-history-dialog th	{
  font-weight:bold;
  font-size:16px;
}
.geofence-history-dialog td	{
  line-height:2.5;
}
