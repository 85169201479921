.phone {
    width: 200px;
    height: 56px;
    border-radius: 5px;
}

.PhoneInputInput {
  background: none;
  padding: 15px;
  border-radius: 5px;
  border: 1px solid var(--text-color);
  color: var(--text-color);
}

.PhoneInput {
  box-shadow: none !important;
}