body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  margin: 0;
  padding: 0;
}

.swiper {
  width: 250px;
  height: 100%;
  cursor: pointer;
  text-align: center;
  align-items: center;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center !important;
  height: 150px !important;
}

/* .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
} */

textarea {
  border-radius: 10px !important;
  background-color: var(--background-color) !important;
  color: var(--text-color);
  padding: 10px !important;
}
